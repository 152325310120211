/* You can add global styles to this file, and also import other style files */

@import "./assets/css/style.css";
@import '../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.css';

// @import "../node_modules/angular-calendar/scss/angular-calendar.scss";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";

@import "../node_modules/@angular/cdk/overlay-prebuilt.css";


@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";




#search-modal ul li {
  @apply cursor-pointer;
}
